import moment from "moment/moment";

export default {
    methods: {
        fieldTitle(field, data) {
            switch (field.type) {
                case 'BOOL':
                    return (data[field.name] === true || data[field.name] === 1 || data[field.name] === '1') ? 'true' : 'false';
                case 'COLOR':
                case 'RELATION':
                    return data[field.name] ? data[field.name]['label'] : '';
                case 'ENUM': {
                    let object;
                    if (field.options) {
                        object = field.options.find(o => o.value === data[field.name]);
                    } else {
                        object = data[field.optionsfield].find(o => o.value === data[field.name]);
                    }
                    return object ? object.text : '';
                }
                case 'DATALIST':
                case 'ID':
                case 'NUMERIC':
                case 'TEXT':
                    return data[field.name];
                case 'DATE':
                case 'DATETIME': {
                    if (data[field.name]) {
                        if (field.type === 'DATE') {
                            return moment(String(data[field.name])).format('DD/MM/YYYY');
                        } else {
                            return moment(String(data[field.name])).format('DD/MM/YYYY HH:mm');
                        }
                    }
                    return '';
                }
                case 'TRANSLATABLE':
                    return data[field.display];
                default:
                    return '';
            }
        },
    }
};